.headerButtonown {
    display: flex;
    justify-content: space-between;
}

.uploaded-images{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    white-space: nowrap;
    overflow: auto;
}

.uploaded-image{
    max-height: 85px;
    height: 100%;
    object-fit: contain;
    padding-right: 0.5rem;
    position: relative;
}

.image-container{
    position: relative;
    display: inline-block;
    margin: 5px;
}

.delete-button{
    position: absolute;
    bottom: 0px;
    right: 5px;
    border:unset;
}

.move-image-left{
    position: absolute;
    left: 0px;
    border:unset;   
}

.move-image-right{
    position: absolute;
    right: 0px;
    border:unset;
}

.checkbox-group{
    height: 200px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 300px;
    margin-top: 10px;
    margin-bottom: 30px;
    
}

.whenbuycarstyle{
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}

.whenbuyheaderstyle{
    font-style: italic;
}

.whenbuymainheader{
    color: white;
    font-weight: bold;
    padding: 20px 0px 20px 0px;
    text-align: center;
    font-style: italic;
}

@media(max-width : 992px) {
    .whenbuycenter{
        text-align: center;
    }
}