.verkstadmainheader {
    margin: 0px;
    padding: 20px;
}

.verkstadwelcome {

    font-size: 40px;
    font-weight: 600;
    background-image: linear-gradient(to right, #bd7a06, #774d04);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    margin-bottom: 20px;
    text-align: left;
}

.datePicker {
    width: 100%;
  }

