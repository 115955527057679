.background-color-standard{
    background-color:yellow;
}

.top-image{
    background-image: url("../Images/top-image.jpeg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    min-height: 980px;
    width: 100%;
    position: relative;
}

@media (max-width : 577px) {
    .top-image{
        background-image: url("../Images/top-image-phone1.jpg");
        min-height: 500px; 
    }
}

.black-filter{
    background-color: var(--theme-home-banner-color);
    bottom: 0;
    min-height: 550px;
    position: absolute;
    width: 100%;
    margin-bottom: 50px;
}

.top-image .filter-box-wrapper{
    position: relative;
    max-width: 1320px;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
    width: 100%;
    display: flex;
    justify-content: center;
}

.top-image .filter-box-wrapper .filter-box{
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    bottom: -230px; /* vart rutan ska placeras*/
    box-shadow: 0 3px 6px 1px rgba(0,0,0,.161);
    /*display: flex;
    flex-direction: column;
    justify-content: flex-start;*/
    min-height: 400px;
    padding: 25px 0px;
    position: relative;
    width: 90%;
    /*z-index: 100;*/
}

.top-image .filter-box-wrapper .filter-box .nav-tabs{
    border:none;
    width: 100%;
    flex-flow: row;
    padding: 0px 20px;
}

.nav {
    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 0.5rem;
    --bs-nav-link-font-weight: ;
    --bs-nav-link-color: var(--bs-link-color);
    --bs-nav-link-hover-color: var(--bs-link-hover-color);
    --bs-nav-link-disabled-color: var(--bs-secondary-color);
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.top-image .filter-box-wrapper .filter-box .nav-tabs .nav-items{
    
    border: 1px solid;
    margin-right: 5px;
    
}

@media(max-width : 577px){
    .top-image .filter-box-wrapper .filter-box .nav-tabs .nav-items{
        border: 1px solid;
     /*    margin-right: 5px;
       padding-top: 10px;
       padding-bottom: 30px; */
    }
    .top-image .filter-box-wrapper .filter-box {
        width: 100%;
        padding-right: 5px !important;
        padding-left: 5px;
        bottom: -340px;
    }

}
.nav-items.active {
    background-color: black; /* Set your desired background color for the active <li> */
    color: white; /* Set the text color for the active <li> */
}


.top-image .filter-box-wrapper .filter-box .nav-tabs .nav-items .nav-link{
    border: 1px solid var(--brand-primary);
    border-radius: 0;
    color: #3b4051;
    color: var(--brand-primary)!important;
    height: 50px;
    justify-content: center;
    margin-right: 10px;
    text-align: center;
    transition: all .2s linear;
    width: calc(100% - 20px);
    
}

.top-image .filter-box-wrapper .filter-box .filter-content-box{
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    width: 100%;
}

.top-image .filter-box-wrapper .filter-box .filter-grid{
    width: 100%;
}

.top-image .filter-box-wrapper .filter-box .filter-grid .filter-row{
    padding: 10px 20px;
}


.sellinput1{
    border: none;
    border-bottom: 1px solid #adadad;
    border-radius: 0;
    box-shadow: none;
    height: 40px;
    margin: 5px;
    outline: none;
    padding: 0;
}

.sellinput-textarea{
    border: none;
    border-bottom: 1px solid #adadad;
    border-radius: 0;
    box-shadow: none;
    margin: 5px;
    outline: none;
    padding: 0;
}

.btn-showallcar{
    border-radius: 20px;
    bottom: 20px;
    width: 50%;
    
}
@media(max-width : 577px){
    .btn-showallcar{
        width: 100%;
    }
}

.mymoneyImage{
    background-image: url("../Images/mymoney.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    
}

.icaImage{
    background-image: url("../Images/Icaforsakring.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    
}

.verkstadimage{
    background-image: url("../Images/verkstadimage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 500px;
    width: 100%;
    
}

@media(max-width : 1400px){
    .mymoneyImage{
        min-height: 400px;
    } 
    .icaImage{
        min-height: 400px;
    }
    .verkstadimage{
        min-height: 400px;
    }
}

@media(max-width : 450px){
    .mymoneyImage{
        min-height: 300px;
    } 
    .icaImage{
        min-height: 300px;
    }
    .verkstadimage{
        min-height: 300px;
    }
}

@media(min-width : 576px) and (max-width: 991px){
    .mymoneyImage{
        min-height: 400px;
        background-size: contain;
    } 
    .icaImage{
        background-size: contain;
        min-height: 300px;
    }
    .verkstadimage{
        background-size: contain;
        min-height: 300px;
    }
}



@media(max-width : 991px){
.carloandirection{
    display: flex;
    flex-direction: column-reverse;
}
}


.ourserviceMainDiv{
    padding-top: 124px;
    padding-bottom: 24px;
    margin: 0px 24px 0.5rem 24px;
    
}

.ourservicediv{
    text-align: center;
    align-items: center;
    display: grid;
}


@media(max-width : 577px){
  .ourserviceMainDiv{
    padding-top: 180px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
}


.ourserviceHeader{
    color: #003e70;
}

.paddingzero{
    padding: 0px !important;
}

.lidotstyle{
    margin: 5px 0;
    list-style-position: inside;
    text-align: justify;
}

.ulicastyle{
    margin-right: 20px;
    text-align-last: center;
    
}

.senastebilarstyle{
    font-size: 35px;
  font-weight: 600;
  background-image: linear-gradient(to right, #bd7a06, #774d04);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  margin-bottom: 20px;
  text-align: left;
  width: 80%;
}

@media(max-width : 577px){
    .senastebilarstyle{
        font-size: 30px;
        margin-top: 20px;
        margin-bottom: 25px;
    } 
}

.bilverkstad-text-center{
    margin-right: 40px;
    margin-left: 40px;
}

.reklamheadingtext{
    color:#003e70;
    font-size: 27px;
    margin-right: 10px;
    margin-left: 10px;
}



.senastebilardiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
   }


   .seanstebilarknappphone{
    display: flex;
    justify-content: center;
    
   }

   @media (min-width : 577px) {
        .seanstebilarknappphone{
            display: none;
        }
   }

   .computervisible_textarea{
    display: block;
}

.phonevisible_textarea{
    display: none;
}

@media(max-width : 577px){
    .top-image .filter-box-wrapper .filter-box .nav-tabs{
        justify-content: space-around;
    }

    .top-image .filter-box-wrapper .filter-box .nav-tabs .nav-items .nav-link{
        height: unset;
        margin-right: unset;
        width: auto;
    }

    .senastebilarstyle{
        width: 100%;
        text-align: center;
        margin-top: 170px;
    }

    .seanstebilarknapp{
        display: none;
    }
    .computervisible_textarea{
        display: none;
    }
    .phonevisible_textarea{
        display: block;
    }
    .black-filter{
        margin-bottom: 0px;
    }
 

}

