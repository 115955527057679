.faqmain{
    margin-right: 100px;
    margin-left:100px

}

@media (max-width :991px) {
    .faqmain{
        margin-left: 10px;
        margin-right :10px;
    }
}

.accordion-button:focus {
    box-shadow: none !important;
}

.faqheader{
    
        font-size: 35px;
        font-weight: 600;
        background-image: linear-gradient(to right, #bd7a06, #774d04);
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        margin-bottom: 20px;
        text-align: left;
        width: 80%;
    
    
}