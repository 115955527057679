.carinfo{
    padding: 5px 10px 10px;
    font-size: 14px;
}

@media (min-width: 1024px){
    .carinfo{
        padding-right: 15px;
        padding-left:15px;
    }
}

.carinfotitle h2{
    font-size: 16px;
}

.carinfotitle{
    font-size: 12px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    font-size: 18px;
    gap: 12px;
}

.carinfotitleh2{
    overflow: hidden;
    width: 65%;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 700;
    line-height: 1.5;
}

.carinfoprice{
    font-size: 16px;
    min-width: fit-content;
    margin: 0;
    text-align: right;
}

.carinfotitleprice{
    margin-bottom: 2px;
    color: #003e70;
    font-weight: 700;
}


.carinfosubtitle1.carinfosubtitle2{
    font-size: 14px;
    margin-bottom: 5px;
}

.carinfosubtitle2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}
.carinfosubtitleheading1{
    overflow: hidden;
    margin: 0 5px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
    line-height: 1.5;
    font-size: 14px;
}
.carinfosubtitlefromprice{
    white-space: nowrap;
    display: flex;
    align-items: center;
    text-align: right;
    min-height: 25px;
}

.carinfsecondsub{
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    height: auto;

}

.carinfosecondsub2{
    max-width: 160px;
}

.carinfosecondsubul{
    display: flex;
    flex-wrap: nowrap;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

.carinfosecondsubli{
    white-space: nowrap;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 4px 5px 0 0;
    padding-top: 0;
}

.carinfosecondsubli:first-child {
    padding-left: 0;
}

.carinfosecondsubli:last-child {
    padding-right: 0;
}
.specdot{
    padding-right: 5px;
}

.imgContainer___BjVIZ{
    position: relative;
    width: 100%;
    padding-top: 56.25%;
} 
.watermark___1OwwC:after {
        position: absolute;
        z-index: 1;
        right: 15px;
        bottom: 15px;
        width: 107px;
        height: 13px;
        content: "";
        background-repeat: no-repeat;
    
}

.img___2b98c {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
}

.car_linkA{
    position: relative;
    z-index: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: opacity .35s ease-in;
    border: 1px solid #dde2e5;
    border-radius: 5px;
    background-color: #fff;
    color: inherit;
    text-decoration: none;

}

.swiperr-slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    touch-action: pan-y;
    
}

.car_linkA:hover {
    /* Add thicker border and other styles for the hover state */
    border: 2px solid grey; /* Adjust the border thickness and color as needed */
}

.seeCarButtonContainer{
    padding: 0 15px 20px
}

.seeCarButton{
    width: 100%;
    height: 46px;
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0 24px;
    margin-bottom: 0;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 5px;
    line-height: 1.5;
    font-weight: 700;
    font-family: Lato,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #fff;
    background-color: rgb(17, 17, 31);
    cursor: pointer;
    user-select: none;
    white-space: nowrap
}

