.sellcarvarderingmain {
    margin: 40px;
}

.SellcarHeader {
    font-size: 35px;
    font-weight: 600;
    background-image: linear-gradient(to right, #bd7a06, #774d04);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    margin-bottom: 20px;
    text-align: left;
    width: 80%;

}