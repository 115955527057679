.mapimage{
    max-width: 600px;
    overflow: hidden;
}

@media (max-width: 1200px) {
    .custom-row {
      flex-direction: column;
      
    }
    .custom-col {
      width: 100%;
      margin-top: 20px;
    }
  }
  
  .mapstyle{
    display: flex;
  }
  /* For screens with a width of 577px or more */
