button.text-white.navbar-toggler.collapsed {
    
}

button.text-white.navbar-toggler {
   
}

/* custom-navbar.css */
/* Move the Navbar.Toggle button slightly to the right */
.navbar-toggler {
    margin-left: 5px; /* Adjust the value as needed */
  }
  
  /* Nav.css */
/* Style the navigation menu items with a transparent underline by default */
.nav-link {
    position: relative;
    text-decoration: none; /* Remove default text decoration (including underline) */
    color: white; /* Set the text color to white */
    font-size: 1.3rem;
    font-style: italic;
  }
  
  /* Add a white underline when hovering over the navigation menu items */
  .nav-link::before {
    content: "";
    position: absolute;
    bottom: -2px; /* Adjust this value to control the thickness of the underline */
    left: 0;
    width: 100%;
    height: 2px; /* Adjust this value to control the thickness of the underline */
    background-color: white;
    transform: scaleX(0); /* Initially, set the underline width to 0 */
    transition: transform 0.3s ease-in-out;
  }
  
  .navclassmargin .nav-link:hover::before {
    transform: scaleX(1); /* Expand the underline width to 100% on hover */
  }
  .navclassmargin2 .nav-link:hover::before {
    transform: scaleX(1); /* Expand the underline width to 100% on hover */
  }


  @media (min-width: 768px) {
    .navclassmargin{
      margin-left: 50px;
    }
  }
   
  

  .navclassmarginright{
    margin-right: 10px;
  }
  
  .navbar-toggler:focus {
    box-shadow: unset !important;
  }

  .navbarfollowingdown{
    position: sticky;
  top: 0;
  z-index: 100;
  background-color: #11111F;
  width: 100%;
  }

  

  .divundernav{
    /* background-image: linear-gradient(90deg,#a33847,#572b64); */
    background-image: linear-gradient(90deg,#df8f06,#624803);
    align-items: center;
    text-align: center;
    font-size: 1.3rem;
    color:#fff;
    padding-top: 15px;
    padding-bottom: 5px;
    font-family: Rajdhani,Open Sans,sans-serif;
  }

  @media (min-width: 992px){
.navbar-expand-lg .navbar-collapse {
  justify-content: center;
}
  }


  @media(max-width : 577px){
    .divundernav{
      font-size: 1rem;
    }
  }