.car-page_margin {
  margin: 30px 20px 0px 20px;
}

.carousel__item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f1f1;

  /* Background color for carousel items */
}

/* Styling for thumbnail images */
.carousel__item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

}

/* Style for active thumbnail image */
.carousel__item.swiper-slide-active img {
  transform: scale(1.1);
  /* Zoom in on active thumbnail */
}

/* Style for product title */
h2 {
  font-size: 24px;
  color: #333;
  margin: 10px 0;
}

/* Add any additional styles you need */


/* Style for the thumbnail container */
.thumbnail-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  /* Adjust the gap between thumbnails */
  margin-top: 20px;
  /* Add spacing between main image and thumbnails */

}

/* Style for the thumbnail images */


/* Style for the active thumbnail image */
.thumbnail-image:hover {
  transform: scale(1.1);
  /* Zoom in on hover */
}

.large-image-container {
  max-height: 400px;
  /* Set the desired maximum height */
  max-width: 100%;
  /* Adjust the maximum width as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* Add this line to hide any overflow */
}

@media (min-width : 1200px) {
  .large-image-container {
    max-height: 500px;
  }

}

.large-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  /* Adjust 'contain' to 'cover' or other options based on your preference */
}




.thumbnail-image {
  max-height: 85px;
  /* Set the desired width */
  height: 100%;
  /* Set the desired height */
  object-fit: contain;
  cursor: pointer;
  padding-right: 0.5rem;


}

.thumbnail-scroll-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;

  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

@media(max-width : 992px) {
  .thumbnail-scroll-button {
    display: none;
  }

  .thumbnail-container {
    display: block;
  }

  .pos-rel {
    padding: 0px !important;
  }

  .car-page_margin {
    margin: 0px;
  }
}

.thumbnail-scroll-button.left {

  font-size: 40px;
  top: 50px;
  position: relative;
}

.thumbnail-scroll-button.right {

  font-size: 40px;
  top: 50px;
  position: relative;

}


@media screen and (min-width: 1024px) {
  .car-page__meta {
    padding: 0;
  }
}

.product-page__meta {
  padding: 0 1rem;
}

.car-page__meta-section {
  padding: 0 0.6875rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.car-page__meta-title {
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-top: 2rem;
}

@media screen and (min-width: 1024px) {
  .car-page__meta-title {
    margin-top: 0;
  }
}

.car-page__meta-subtitle {
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 300;
  font-family: var(--font-base, "Open Sans", sans-serif);
  text-transform: none;
  margin-bottom: 0.875rem;
  color: #000 !important;
}

.car-page__meta-section hr {
  border-color: #9f9f9f;
}

.car-page__meta-section {
  padding: 0 0.6875rem;
  font-size: .875rem;
  line-height: 1.5rem;
}

.car-page__meta-price {
  font-size: 1.5625rem;
  line-height: 1.8125rem;
  font-weight: 700;
}

.activeimgowncode {
  height: 100%;
  object-fit: cover;
  object-position: center;
  max-height: 512px;
}

.car-page__meta-choice {
  border: 1px solid #ececec;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
  padding: 0.5rem 1rem;
}

.car-page__meta-choice-title {
  font-size: .875rem;
  line-height: 1.25rem;
}

.price-detail {
  font-size: .75rem;
  line-height: 1rem;
  letter-spacing: 0;
  float: right !important;
}

.price-detail .main-value {
  margin: 0;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4375rem;
}

.price-detail .main-value__suffix {
  font-size: .75rem;
  line-height: 1rem;
}

.car-page__meta-choice-meta {
  font-size: .75rem;
  line-height: 1.0625rem;
}



.h-mb-2 {
  margin-bottom: 1rem !important;
}

.carDetailsMain {
  background-color: #fff9f1;
}

.carDetailsMainHeader {
  font-size: 24px;
  line-height: 1.5rem;
  font-weight: bold;
  font-style: italic;
  padding-top: 20px;
  padding-bottom: 10px;
}

.carDetailsMain-maininfo {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  padding-right: 100px;
}

@media(max-width : 992px) {
  .carDetailsMain-maininfo {
    padding-right: 0px;
    flex-flow: column;
    padding-left: 10px;
  }
  .carDetailsMain-itemOverview {
    margin-right: 2rem;
  }
  .carDetailsMainHeader {
    padding-left: 10px;
  }
  
}

.carDetailsMain-itemOverview {
  display: flex;
  width: 125px;
  font-size: 1.2rem;
  line-height: 1.125rem;
  margin-right: 0.5rem;
  margin-bottom: 1.75rem;

}

.cardetailsiconcode{
  margin-right: 0.5rem;
    width: 2rem;
    height: auto;
    align-self: center;
}

.carEquipmentMenu{
  display: flex;
  flex-flow: wrap;
  flex-flow: row;
  justify-content: space-between;
  padding-right: 100px;
}

@media (max-width : 992px) {
  .carEquipmentMenu{
    flex-flow: column;
  }
}

.contactdivcode{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
}

.contactus-margin{
  margin-top: 10px;
 margin-bottom: 15px;
}

.car-page__description-section {
  padding: 0 0.6875rem;
  font-size: 1.0rem;
  line-height: 1.5rem;
  font-style: italic;
}

@media(max-width : 577px){
  .phone-margin-formular{
      margin-bottom: 15px;
  }
}

.modal-fullscreen {
  width: 100vw;
  height: 100vh;
  margin: 0;
  max-width: none;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  overflow: hidden;
  object-fit: contain;
  background-color: #000 !important;
}

.modal-fullscreen .modal-content {
 object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.modalbodytest{overflow: unset; height: fit-content;}

.modal-fullscreen .modal-body {
  overflow-y: unset;
   object-fit: contain;
   background-color: #000;
   padding: 0px;
}

.imagetest10 {
  object-fit: contain;
  height: 86vh!important;
}

.enlargebutton{
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}

.enlargeicon:hover {
  font-size: 40px !important; /* Increase the size on hover */
  transition: font-size 0.3s ease; /* Add a smooth transition */
}